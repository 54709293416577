import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const ContractorLogo = () => {
    const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "contractor_logo.png" }) {
        childImageSharp {
          fixed (width: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

    if (!data?.logo?.childImageSharp?.fixed) {
        return <div>Picture not found</div>
    }

    return       <Img className={'mt-4 mb-4 float-right'} fixed={data.logo.childImageSharp.fixed} alt="Gatsby Docs are awesome"/>
}

export default ContractorLogo
