import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'
import ContractorLogo from "./contractor_logo";

const BackgroundSection = ({ className }) => (
    <StaticQuery
        query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg_image.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => {
            // Set ImageData.
            const imageData = data.desktop.childImageSharp.fluid
            return (
                <BackgroundImage
                    Tag="section"
                    className={'pt-32'}
                    fluid={imageData}
                    loading={"eager"}
                    backgroundColor={`#040e18`}
                >
                  <div className={'max-w-screen-lg mx-auto relative'}>
                      <div className={'md:w-1/2 bg-indigo-700 text-white p-2'}>
                          <h1 className={'text-xl'}>Welcome to<br />
                              <ContractorLogo/>
                            <span className={'font-extrabold'}>Silo Cleaning Services (UK) LLP </span>
                            <br />Selby North Yorkshire</h1>
                        <div className={'text-sm'}>Silo cleaning services has been established since 1975, giving a total professional service to the Food Production, Flour Milling and Bakery Industries throughout the United Kingdom..</div>
                          <Link className={'leading-10 rounded-md text-white bg-gray-800 p-2'} to="/about-us">More Information</Link>
                      </div>
                  </div>
                </BackgroundImage>
            )
        }}
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection