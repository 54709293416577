import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import HealthImage from "../components/health_image";
import AboutImage from "../components/about_image";
import ServicesImage from "../components/service_image";
import ContactImage from "../components/contact_image";
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/home_header";

const IndexPage = () => (
  <Layout >
    <SEO title="Home" description="Silo cleaning services - We provide Silo cleaning services throughout the UK" />
    <StyledBackgroundSection/>
    <div className={'max-w-screen-lg mx-auto'}>
      <div className={'flex flex-wrap text-white mt-4'}>
          <div className={'flex-1 pb-2 my-2 mr-2 bg-yellow-500'}>
              <AboutImage />
              <div className={'px-4'}>
                <h2 className={'text-2xl font-normal mb-2'}>about us</h2>
                <p>Silo cleaning services has now been operating for over 35 years, giving a total professional service to the Food Production, Flour Milling and Bakery Industries throughout the United Kingdom...</p>
              </div>
              <div className={'text-center my-4'}>
                  <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/about-us">More Information</Link>
              </div>
          </div>
          <div className={'flex-1 pb-2 m-2 bg-orange-500'}>
              <ServicesImage />
              <div className={'px-4'}>
                <h2 className={'text-2xl font-normal mb-2'}>our services</h2>
                <p>Silo cleaning has a tendency to be put to the “back of the queue” – put off until the necessity is overwhelming therefore causing problems such as build up of material, infestation etc.</p>
              </div>
              <div className={'text-center my-4'}>
                  <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/our-services">More Information</Link>
              </div>
          </div>
          <div className={'flex-1 pb-2 m-2 bg-teal-700'}>
              <HealthImage />
              <div className={'px-4'}>
                <h2 className={'text-2xl font-normal mb-2'}>health & safety</h2>
                <p>Due to the nature of our business, health and safety is paramount to Silo Cleaning Services and have a wide range of safety rules and documentation.</p>
              </div>
              <div className={'text-center my-4'}>
                  <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/health-and-safety">More Information</Link>
              </div>
          </div>
          <div className={'flex-1 pb-2 m-2 ml-2 bg-indigo-700'}>
              <ContactImage />
              <div className={'px-4'}>
                <h2 className={'text-2xl font-normal mb-2'}>contact us</h2>
                <p>Silo Cleaning Services (UK) LLP <br/>Unit 3, Oakney Wood Court <br/>Selby <br/>North Yorkshire <br/>YO8 8FN</p>
              </div>
              <div className={'text-center mt-10'}>
                  <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/contact-us">More Information</Link>
              </div>
          </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
